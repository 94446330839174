:root {
  --custom-color: #017c6b;
  --custom-drawer-background-color: #f7f7f7;
}

.nav-bar-container {
  display: flex;
  height: 60px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.2);
  margin-bottom: 5px;
  position: relative;
  color: var(--custom-color);
  width: 80%;
  margin: auto;
}

.icon-background {
  background-color: var(--custom-drawer-background-color);
}

.navbar-header {
  position: absolute;
  display: flex;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 20px;
  z-index: +5;
}

.navbar-header .navbar-header-name {
  color: #017c6b;
}

.header-name-span {
  color: red;
}

.navbar-header a {
  text-decoration: none;
}

.nav-bar-links {
  display: gird;
  grid-template-columns: 3;
  position: absolute;
  right: 0;
  top: 30px;
  margin-right: 60px;
  font-size: 20px;
  font-weight: bold;
  transform: translateY(-50%);
}

.nav-bar-link {
  text-decoration: none;
  margin-left: 200px;
  text-shadow: 0 1px 1px;
  color: var(--custom-color);
}

@media only screen and (max-width: 1200px) {
  .nav-bar-link {
    margin-left: 100px;
  }
  .nav-bar-container {
    width: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .nav-bar-link {
    margin-left: 70px;
  }
}

@media only screen and (max-width: 800px) {
  .nav-bar-link {
    margin-left: 50px;
  }
  .nav-bar-links {
    margin-right: 50px;
  }
}

@media only screen and (max-width: 640px) {
  .nav-bar-links {
    display: none;
  }
}

@media only screen and (min-width: 641px) {
}

.toggler-button:hover {
  cursor: pointer;
}

.drawer-container {
  position: fixed;
  right: 0;
  top: 2px;
  width: 15rem;
  height: 100vh;
  text-align: center;
  font-size: 30px;
  overflow: hidden;
  z-index: +15;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--custom-drawer-background-color);
}

.drawer-container ul a {
  text-decoration: none;
  color: var(--custom-color);
  display: grid;
  margin-bottom: 10px;
  font-weight: bold;
  background-color: var(--custom-drawer-background-color);
  font-size: 40px;
}

.drawer-container ul {
  background-color: var(--custom-drawer-background-color);
  margin-top: 86px;
}

.drawer-container a {
  text-decoration: none;
  color: black;
  display: grid;
}
.icon-style {
  justify-content: center;
  margin-top: 20px;
  font-size: 50px;
  background-color: var(--custom-drawer-background-color);
}

.hide-drawer {
  right: -15rem;
  transition: 1000ms;
}

.show-drawer {
  right: 0;
  transition: 1000ms;
}

@media only screen and (min-width: 900px) {
  .drawer-container {
    width: 30rem;
  }
  .hide-drawer {
    right: -30rem;
    transition: 1500ms;
  }
}

@media only screen and (max-width: 1200px) {
  .desktop-menu-items ul a {
    margin-right: 4rem;
  }
  .drawer-container ul a {
    font-size: 30px;
  }
}

@media only screen and (max-width: 800px) {
  .desktop-menu-items ul a {
    margin-right: 1rem;
  }
}
