.home-page-container {
  position: relative;
  overflow: hidden;
}

.home-page-img-container {
  position: relative;
}

.home-page-image {
  width: 100%;
}

.search-container {
  position: absolute;
  left: 50%;
  top: 30px;
  transform: translateY(-50%);
  transform: translateX(calc(-50% - 40px));
}

.search-input-field {
  width: 400px;
  height: 40px;
  padding: 0 10px 0 10px;
  font-size: 25px;
  border-radius: 10px 0 0 10px;
  color: #017c6b;
}

.search-button {
  width: 80px;
  height: 40px;
  border-radius: 0 10px 10px 0;
  position: absolute;
  left: 50%;
  transform: translateX(200px);
  font-size: 30px;
  font-weight: bold;
  color: #017c6b;
}
.search-button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 900px) {
  .home-page-welcome-two {
    display: none;
  }

  .home-page-welcome {
    display: none;
  }
}

@media only screen and (max-width: 640px) {
  .search-container {
    height: 10rem;
    opacity: 0.5;
    position: relative;
    left: 0;
    transform: translateY(0);
    transform: translateX(0);
    display: flex;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.4);
  }

  .search-input-field {
    margin: 0;
    width: 80%;
    border: 1px solid black;
    transform: translateX(-25px);
    outline: none;
    background-color: white;
    color: black;
  }

  .search-button {
    width: 20%;
    transform: translateX(100px);
    font-size: 30px;
    font-weight: bold;
    color: #017c6b;
    left: 80%;
    transform: translateX(-20%);
    background-color: white;
    border: 1px solid black;
    color: black;
  }

  .search-container:hover {
    opacity: 1;
  }

  .our-products {
    margin-top: 110px;
  }
}
