* {
  font-family: "Raleway", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: white;
}

.main-section {
  min-height: calc(100vh - 210px);
}
