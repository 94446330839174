.contact-form-container {
  height: 100%;
  position: relative;
  top: 20%;
  text-align: center;
  display: flex;
  flex-direction: column;
}

.email-label {
  margin-top: 20px;
  margin-bottom: 5px;
}

.contact-email-input {
  width: 60%;
  border-radius: 5px;
  height: 40px;
  outline: none;
  border: 0;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.4);
  font-size: 20px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  -webkit-appearance: none;
}

.message-label {
  margin-top: 20px;
  margin-bottom: 5px;
}

.contact-message-input {
  width: 60%;
  border-radius: 5px;
  height: 80px;
  outline: none;
  border: 0;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.4);
  font-size: 20px;
  padding: 5px 10px 5px 10px;
  -webkit-appearance: none;
}

.contact-submit-button {
  margin-top: 20px;
  width: 200px;
  height: 50px;
  background-color: white;
  outline: none;
  border: 0;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.4);
  font-size: 25px;
  border-radius: 5px;
  color: black;
}

@media screen and (max-width: 1700px) {
  .contact-message-input {
    width: 80%;
  }
  .contact-email-input {
    width: 80%;
  }
}

@media only screen and (max-width: 1300px) {
  .contact-form-container {
    top: 40px;
  }
  .contact-message-input {
    width: 60%;
  }
  .contact-email-input {
    width: 60%;
  }
}

@media only screen and (max-width: 750px) {
  .contact-message-input {
    width: 80%;
  }
  .contact-email-input {
    width: 80%;
  }
}

@media only screen and (max-width: 640px) {
  .contact-form-container {
    top: 20px;
  }
  .contact-message-input {
    box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.4);

    width: 90%;
  }
  .contact-email-input {
    width: 90%;
  }
}
