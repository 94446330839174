.contact-page-container {
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  position: relative;
}

.contact-gif-container {
  display: flex;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

.gif-img {
  width: 60%;
}

@media only screen and (max-width: 2000px) {
  .contact-gif-container {
  }
}

@media only screen and (max-width: 1300px) {
  .contact-page-container {
    grid-template-columns: 1fr;
    width: 100%;
  }
}

@media only screen and (max-width: 640px) {
  .contact-page-container {
    grid-template-columns: 1fr;
    width: 100%;
  }
}
