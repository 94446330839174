.user-location {
  text-align: center;
  margin: 20px;
}

.main-single-product-container {
  overflow: hidden;
}

.single-product-container {
  width: 80%;
  height: 100%;
  margin: 40px auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  justify-content: center;
  align-items: center;
}

.single-product-image-container {
  text-align: center;
  justify-content: center;
}

.single-product-detail-container {
  position: relative;
}

.single-product-title {
  text-align: center;
}

.single-product-description {
  margin: 50px 0;
  font-weight: normal;
  font-size: 22px;
}

.single-product-image {
  width: 80%;
  max-height: 800px;
  object-fit: cover;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  margin-top: 60px;
}

.single-product-buy-on {
  text-align: center;
  color: red;
}

.single-item-card-amazon {
  display: flex;
  margin: 20px auto;
  text-align: center;
}

.single-item-card-amazon-image {
  text-align: start;
  width: 30%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.single-item-card-amazon-image:hover {
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.5);
}

.single-item-card-website-image {
  text-align: start;
  width: 35%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  padding-right: 20px;
  padding-left: 20px;
}

.single-item-card-website-image:hover {
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.5);
}

.set-web-link-width {
  width: 70%;
  margin-left: 15%;
}

.single-item-card-image {
  width: 300px;
  max-height: 400px;
  object-fit: cover;
}

.single-product-aditional_informations {
  width: 80%;
  margin: 0 auto 30px auto;
  font-size: 18px;
}

.single-product-title-slmall-screen-display {
  display: none;
}

.amazon-generated-html {
  position: relative;
  left: 50%;
  transform: translateX(-60px);
  margin-bottom: 20px;
}

.single-item-cb-button {
  width: 200px;
  height: 80px;
  background-color: white;
  font-size: 25px;
  font-weight: bold;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border: 0px;
  color: black;
}

.single-item-cb-button:hover {
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

@media screen and (max-width: 1200px) {
  .single-product-container {
    grid-template-columns: 1fr;
    width: 100%;
  }
  .single-product-image {
    width: 60%;
    max-height: 100%;
    object-fit: cover;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }
  .single-product-description {
    margin: 50px 80px;
  }

  .single-product-title-slmall-screen-display {
    display: inline;
    text-align: center;
    margin: 0 20px 0 20px;
  }

  .single-product-title {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .single-product-container {
    grid-template-columns: 1fr;
    width: 100%;
  }
  .single-product-image {
    width: 70%;
    max-height: 100%;
    object-fit: cover;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }
  .single-product-description {
    margin: 50px 40px;
  }

  .single-product-aditional_informations {
    width: 85%;
    font-size: 18px;
  }
}

@media screen and (max-width: 600px) {
  .single-product-container {
    grid-template-columns: 1fr;
    width: 100%;
  }
  .single-product-image {
    width: 90%;
    max-height: 100%;
    object-fit: cover;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }

  .single-product-title-sm-screen {
    font-size: 23px;
    text-align: center;
    margin: 30px 10px 0 10px;
  }

  .single-product-description {
    font-size: 20px;
  }

  .single-product-title-sm-screen {
    margin: 0 5px 0 5px;
    width: 100%;
    font-size: 20px;
  }

  .single-product-aditional_informations {
    width: 95%;
    font-size: 18px;
  }
}
