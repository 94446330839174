.all-items-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 50px auto;
  overflow: hidden;
  gap: 20px;
  text-align: center;
  width: 80%;
  position: relative;
  align-items: center;
  justify-content: center;
  padding: 0 5px 0 5px;
}

.all-item-card-container {
  margin: 10px auto;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.4);
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 530px;
}

.all-item-card-title {
  margin-top: 20px;
  margin-bottom: 20px;
}

.all-item-card-title:hover {
  cursor: pointer;
}

.all-item-card-amazon {
  display: flex;
}

.all-item-card-amazon-image {
  text-align: start;
  width: 60%;
}

.all-item-card-website-image {
  text-align: start;
  width: 60%;
  margin-left: 0px;
  height: 70%;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.all-item-card-website-image:hover {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  display: inline;
}

.all-item-card-amazon-image:hover {
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.5);
}

.all-item-card-image {
  width: 300px;
  max-height: 600px;
  object-fit: cover;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.all-item-card-image:hover {
  cursor: pointer;
}

.all-item-website-buy {
  text-decoration: none;
  color: black;
}

.all-item-card-amazon-display {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.all-item-cb-button {
  width: 150px;
  height: 60px;
  background-color: white;
  font-size: 25px;
  font-weight: bold;
  border-radius: 5px;
  border: 0px;
  color: black;
  position: absolute;
  bottom: 40px;
  left: 50%;
  transform: translatex(-75px);
  padding: 0 5px 0 5px;
}

.all-item-cb-button:hover {
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

@media only screen and (max-width: 1300px) {
  .all-items-container {
    grid-template-columns: repeat(2, 1fr);
    width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .all-items-container {
    grid-template-columns: 1fr;
    width: 95%;
    padding: 0 5px 0 5px;
  }
}
