:root {
  --custom-color: #017c6b;
  --custom-drawer-background-color: #f7f7f7;
  --footer-background-color: red;
}

.footer-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  padding-bottom: 20px;
  background-color: black;
}

.footer-container h1 {
  color: var(--custom-color);
  margin-top: 20px;
}

ul {
  list-style-type: none;
  font-size: 20px;
  color: white;
  font-weight: bold;
  text-align: center;
  margin-top: 20px;
}

.footer-container ul li {
  font-weight: normal;
}

.footer-dropdown-icon {
  display: none;
}

.footer-dropdown-icon {
  position: relative;
  top: 5px;
}

@media only screen and (max-width: 640px) {
  .footer-container {
    grid-template-columns: 1fr;
  }
  .products-items {
    display: none;
    cursor: pointer;
  }

  .store-items {
    display: none;
    cursor: pointer;
  }

  .about-items {
    display: none;
    cursor: pointer;
  }

  .footer-dropdown-icon {
    display: inline;
    position: relative;
    top: 5px;
  }
}
