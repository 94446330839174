.about-text-wrapper {
  display: grid;
  grid-template-columns: 1;
  width: 100%;
  height: 20rem;
  background-color: #e3e2e1;
  margin-top: 2px;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 25px;
}

.aboutus-text {
  color: #21ade2;
}

.get-what-you-need {
  margin: 5rem 10rem 0 10rem;
}

.aboutus-descripion {
  margin: 5rem 10rem 50px 10rem;
  font-size: 20px;
}

@media only screen and (max-width: 1200px) {
  .about-text-wrapper {
    height: 15rem;
    font-size: 20px;
  }

  .aboutus-descripion {
    font-size: 18px;
  }
}

@media only screen and (max-width: 750px) {
  .about-text-wrapper {
    height: 10rem;
    font-size: 18px;
  }

  .get-what-you-need {
    margin: 5rem 0 0 0;
    font-size: 20px;
    text-align: center;
  }
  .aboutus-descripion {
    margin: 5rem 10px 50px 10px;
    text-align: center;
  }
}
