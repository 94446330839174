.loading-line {
  width: 100%;
  height: 2px;
  background-color: #017c6b;
  animation: 2s ease-out 1s 1 loading-line-transition;
}

@keyframes loading-line-transition {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
