.subscribe-form-container {
  width: 80%;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.subscribe-form-input {
  width: 40%;
  border-radius: 5px;
  height: 40px;
  outline: none;
  border: 0;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.4);
  font-size: 20px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  -webkit-appearance: none;
}

.subscribe-form-label {
  font-size: 20px;
}

.subscribe-submit-button {
  margin-top: 20px;
  width: 150px;
  height: 50px;
  background-color: white;
  outline: none;
  border: 0;
  box-shadow: 2px 2px 4px 2px rgba(0, 0, 0, 0.4);
  font-size: 25px;
  border-radius: 5px;
  color: black;
}

.subscribe-submit-button:hover {
  cursor: pointer;
}

@media only screen and (max-width: 1200px) {
  .subscribe-form-input {
    width: 80%;
  }

  .subscribe-submit-button {
    width: 150px;
    font-size: 22px;
  }
  .subscribe-label {
    font-size: 24px;
  }
}

@media only screen and (max-width: 900px) {
  .subscribe-form-container {
    width: 85%;
  }

  .subscribe-form-input {
    width: 80%;
  }

  .subscribe-submit-button {
    width: 130px;
    font-size: 20px;
  }

  .subscribe-label {
    font-size: 22px;
  }
}

@media only screen and (max-width: 750px) {
  .subscribe-form-container {
    width: 95%;
  }

  .subscribe-form-input {
    width: 100%;
  }

  .subscribe-submit-button {
    width: 100px;
    font-size: 18px;
  }

  .subscribe-label {
    font-size: 20px;
  }
}
